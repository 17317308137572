/*
 *
 * BRNO
 * Author: Martin Winkler @ ORWIN
 * url: http://www.orwin.cz/
 *
 */

/**********************************************
VARIOUS
**********************************************/

// Scrollování ke kotvě
$(function() {
	$('.to-anchor').scrollToAnchor();
});

// Dropdown
$('[data-purpose="dropdown-trig"]').on('click', function() {
	var trigger = $(this);
	var id = $(this).data('id');
	var dropdown = $(document.getElementById(id));
	var dropdowns = $('[data-purpose="dropdown"]');
	var header = $('.header');

	if (!dropdown.hasClass('active')) {
		dropdowns.removeClass('active');
		dropdown.addClass('active');
		$('[data-purpose="dropdown-trig"]').closest('li').removeClass('dropdown-opened');
		trigger.closest('li').addClass('dropdown-opened');
		header.addClass('dropdown-opened');
		trigger.addClass('active');
	} else {
		dropdown.removeClass('active');
		trigger.closest('li').removeClass('dropdown-opened');
		header.removeClass('dropdown-opened');
		trigger.removeClass('active');
	}

	return false;
});

// Search - header
$('[data-purpose="search-trig"]').on('click', function() {
	var trigger = $('[data-purpose="search-trig"]');
	var activeTrigger = $('[data-purpose="dropdown-trig"].active');
	var search = $('.search-input');
	var nav = $('.main-nav');

	activeTrigger.trigger('click');

	if ($('body').hasClass('mobile-nav-opened')) {
		$('.nav-btn').trigger('click');
	}

	if (!search.hasClass('active')) {
		$('body').addClass('searching');
		nav.addClass('hidden');
		trigger.addClass('active');
		search.addClass('active');
		document.querySelector('[data-purpose="search-header"]').focus();
	} else {
		$('body').removeClass('searching');
		nav.removeClass('hidden');
		trigger.removeClass('active');
		search.removeClass('active');
		$('[data-purpose="search-header"]').val('').trigger('keyup');
	}

	return false;
});

$('[data-purpose="search-header"]').on('keyup', function() {
	var searchInputVal = $(this).val();

	if (searchInputVal.length > 0) {
		$('[data-purpose="dropdown-search"').addClass('active');
	} else {
		$('[data-purpose="dropdown-search"').removeClass('active');
	}
});

// Ol fix
$('ol').each(function() {
  var val = 1;
  if ($(this).attr('start')) {
		val = $(this).attr('start');
  }
  val = val - 1;
  val = 'counter ' + val;
	
	$(this).css('counter-increment', val);
});

// File list
/*$('.file-list li').each(function() {
  var li = $(this);
  var href = li.find('a').attr('href');
	var file = href.split('/').pop();
	var ext = file.substr(file.lastIndexOf('.') + 1, file.length);
	
	li.append('<span class="icon-file">'+ ext +'</span>');
});*/

// Responsive table
$('.post-block table').wrap('<div class="table-wrap"></div>');

// Tippy
$(window).on('load calendarSwitch', function() {
	if ($('.tippy').length > 0) {
		$('.tippy').each(function() {
		  var trigger = 'mouseenter focus';
		  var attr = $(this).attr('data-click');
		  
		  if (typeof attr !== typeof undefined && attr !== false) {
		    trigger = 'click';
		  }

		  tippy(this, {
		    content: $(this).find('.tippy-popup').html(),
		    allowHTML: true,
		    trigger: trigger,
		    maxWidth: 480,
		    placement: 'top-start',
		    interactive: 'true',
		    //arrow: 'roundArrow'
		  });
		});
	}
});

// Datepicker
if ($('input[data-purpose="datepicker').length > 0) {
	(function () {
	  Datepicker.locales.en = {
	    days: ["Neděle", "Pondělí", "Úterý", "Středa", "Čtvrtek", "Pátek", "Sobota"],
	    daysShort: ["Ned", "Pon", "Úte", "Stř", "Čtv", "Pát", "Sob"],
	    daysMin: ["Ne", "Po", "Út", "St", "Čt", "Pá", "So"],
	    months: ["Leden", "Únor", "Březen", "Duben", "Květen", "Červen", "Červenec", "Srpen", "Září", "Říjen", "Listopad", "Prosinec"],
	    monthsShort: ["Led", "Úno", "Bře", "Dub", "Kvě", "Čer", "Čnc", "Srp", "Zář", "Říj", "Lis", "Pro"],
	    today: "Dnes",
	    clear: "Vymazat",
	    titleFormat: "MM y",
	    format: "mm/dd/yyyy",
	    weekStart: 1
	  }
	})();

	const elements = document.querySelectorAll('input[data-purpose="datepicker"]');
	// const tomorrow = new Date();
	// tomorrow.setDate(tomorrow.getDate() + 1);
	elements.forEach(elem => {
		const datepicker = new Datepicker(elem, {
			format: 'dd.mm.yyyy',
			weekStart: 1,
			clearBtn: true,
			// minDate: tomorrow,
		});
	})
}

/**********************************************
FORM STYLING
**********************************************/

var checkbox = $('input[type="checkbox"]');

checkbox.each(function(index, element) {
  var wrapper = $(element).wrap('<div class="checker"></div>');

  $(element).on('change', function() {
  	wrapper = $(this).closest('.checker');
  	
    if (element.checked) {
      wrapper.addClass('active');
    } else {
      wrapper.removeClass('active');
    }
  });
});

/**********************************************
NOTIFICATION BAR
**********************************************/

$(function() {
  var webCookie = 'brno_notification_bar';
  var cookieisok = ReadCookie(webCookie);
  var testCookie = '';
  SetCookie('testCookie', 'ok', 365);
  var testCookie = ReadCookie('testCookie');

  $(function() {
    if (cookieisok != "ok" && testCookie == "ok") {
    	$('.notification-bar').addClass('active');
    }

    $('.notification-bar .close').on('click', function() {
      $('.notification-bar').removeClass('active');
      SetCookie(webCookie, 'ok', 365);
    });
  });

  $(function() {
  	$('.notification-trigger').on('click', function() {
  		$('.notification-bar').addClass('active');
  	});  	
  })
});

/**********************************************
MODAL BOX
**********************************************/

$(function() {
  var body 				= $('body');
  var modalClass 	= $('.modal');
  var trigger 		= $('.modal-trigger');
  var close 			= $('.modal-close');

  trigger.each(function(index, element) {
    var id 			= element.hash;
    var modal 	= $(id);

    $(element).on('click', function(e) {
      modal.addClass('active');
      $('body').addClass('modal-opened');
    });
  });

  // Close link
  close.on('click', function(e) {
    modalClass.removeClass('active');
    body.removeClass('modal-opened');
  });

  // Overlay click
  $(window).on('click', function(e) {
    $('.modal-box-wrap').each(function(index, element) {
      if (e.target === element) {
        modalClass.removeClass('active');
        body.removeClass('modal-opened');
      }
    });
  });

  // Escape close
  $(window).on('keyup', function(e) {
    if (e.keyCode === 27) {
      modalClass.removeClass('active');
      body.removeClass('modal-opened');
    }
  });
});

/**********************************************
MOBILE NAV
**********************************************/

$(window).on('resize', function() {
	if ($('.notification-bar-alt').length > 0) {
		var barHeight = $('.notification-bar-alt').height();
		$('.mobile-nav ul.ul-main').css({paddingTop: barHeight});
	}
});

$(function() {
	var body 							= $('body');
	var mobileNav 				= $('.mobile-nav');
	var mobileNavOverlay 	= $('.mobile-nav-overlay');

	function showNav() {
		body.addClass('mobile-nav-opened');

		if ($('.notification-bar-alt').length > 0) {
			var barHeight = $('.notification-bar-alt').height();
			$('.mobile-nav ul.ul-main').css({paddingTop: barHeight});
		}
	}

	function hideNav() {
		body.removeClass('mobile-nav-opened');
	}

	// Navigation button
	$('.nav-btn').on('click', function(e) {
		if (!body.hasClass('mobile-nav-opened')) {
			showNav();
		}
		else {
			hideNav();
		}
		return false;
	});

	// Mobile nav overlay close
	$('.mobile-nav-overlay').on('click', function(e) {
		hideNav();
	});

	$('.mobile-nav li a').each(function(e) {
		if ($(this).siblings('ul').length > 0) {
			$(this).append('<span class="more"></span>');
		}
	});

	// Mobile nav accordion
	$('.mobile-nav li .more').on('click', function(e) {
		var link = $(this).closest('a');
		var ul = link.siblings('ul');

		if (ul.length > 0) {
			if (!ul.hasClass('active')) {
				link.addClass('active');
				ul.addClass('active');
			} else {
				link.removeClass('active');
				ul.removeClass('active');
			}
			return false;
		}
	});
});

/**********************************************
RESIZE END / SCROLL END
**********************************************/

var resizeEnd;
$(window).on('resize', function(e) {
	clearTimeout(resizeEnd);
	resizeEnd = setTimeout(function() {
		$(window).trigger('resizeEnd');
	}, 100);
});

var scrollEnd;
$(window).on('scroll', function(e) {
	clearTimeout(scrollEnd);
	scrollEnd = setTimeout(function() {
		$(window).trigger('scrollEnd');
	}, 100);
});

/**********************************************
SIMPLE LIGHTBOX
**********************************************/

// Simple lightbox
new SimpleLightbox({
  elements: ".fancybox,a[href$='.jpg'], .fancybox,a[href$='.webp'], a[href$='.jpeg'], a[href$='.png'], a[href$='.gif'], [data-fancybox='gallery'], [data-purpose='play']"
});

/**********************************************
SLIDERS
**********************************************/

// Teaser slider
if ($('.teaser-slider').length > 0) {
	new Glide('.teaser-slider', {
	  type: 'carousel',
	  startAt: 0,
	  perView: 1,
	  gap: 200,
	  dragThreshold: 100,
		swipeThreshold: 100,
		autoplay: 5000,
		keyboard: true,
	}).mount()
}

// Tabs
if ($('.tabs').length > 0) {
	new Glide('.tabs', {
	  type: 'slider',
	  startAt: 0,
	  perView: 1,
	  animationDuration: 0,
	  gap: 100,
	  swipeThreshold: false,
	  dragThreshold: false
	}).mount()
}

/****************************************************************************************************************************************************************************************
FUNCTIONS
****************************************************************************************************************************************************************************************/

// Plynulý scroll ke kotvě
(function($) {
	$.fn.scrollToAnchor = function(settings) {

		settings = jQuery.extend({
			speed : 750
		}, settings);

		return this.each(function(){
			var caller = this
			$(caller).click(function (event) {
				event.preventDefault()
				var locationHref = window.location.href;
				var elementClick = $(caller).attr("href");
				var destination = $(elementClick).offset().top;

				$("html:not(:animated),body:not(:animated)").animate({
						scrollTop: destination
					}, {
				  	duration: settings.speed,
				  	//easing: "easeInOutQuint",
				  	complete: function() {
						//history.pushState(null, null, elementClick);
						}
					}
				);
				return false;
			});
		});
	}
})(jQuery);

// Plynulý scroll ke kotvě
function scrollToAnchor(trigger, duration = 200, easing = 'linear', callback) {

  var easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
  };

  var start = window.pageYOffset;
  var startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
  var documentHeight = Math.max(document.body.scrollHeight, document.body.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
  var windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
  var destination = trigger.attr('href');  
  var destinationOffset = $(destination).offset().top;
  var destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

  if ('requestAnimationFrame' in window === false) {
    window.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    var now = 'now' in window.performance ? performance.now() : new Date().getTime();
    var time = Math.min(1, ((now - startTime) / duration));
    var timeFunction = easings[easing](time);
    window.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

    if (window.pageYOffset === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}

// Cookies
function ReadCookie(name) {
  var v = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
  return v ? v[2] : null;
}

function SetCookie(name, value, days) {
  var d = new Date;
  d.setTime(d.getTime() + 24*60*60*1000*days);
  document.cookie = name + "=" + value + ";path=/;expires=" + d.toGMTString();
}

function DeleteCookie(name) {
  setCookie(name, '', -1);
}