$(function () {
	function start(action, noPushState = null) {
		if (!noPushState) {
			window.history.pushState({href: action.href}, '', action.href);
		}

		$.ajax(action.href, {
			type: 'GET',
			data: {
				ts: Date.now()
			},
			beforeSend: function () {
				$('.loader-overlay').show();
			},
			complete: function () {
				$('.loader-overlay').hide();
			},
			success: function (data) {
				jQuery.each(data.snippets, function (snippet, currentValue) {
					$('#' + snippet).html(currentValue);
				});
				$(window).trigger('calendarSwitch');
				if ($(action).attr('data-scrollTo')) {
					// window.scrollTo(0, $('#' + $(action).attr('data-scrollTo')).offset().top)
					$("html:not(:animated),body:not(:animated)").animate({
								scrollTop: $('#' + $(action).attr('data-scrollTo')).offset().top
							}, {
								duration: 1000,
								complete: function () {
								}
							}
					);
				}
			},
			error: function () {
				console.log('error');
			}
		});
	};

	function ajaxCall(form, formData) {
		$.ajax(form.attr('action'), {
			type: form.attr('method'),
			data: formData,
			beforeSend: function () {
				$('.loader-overlay').show();
			},
			complete: function () {
				$('.loader-overlay').hide();
			},
			success: function (data) {
				jQuery.each(data.snippets, function (snippet, currentValue) {
					$('#' + snippet).html(currentValue);
				});
				$(window).trigger('calendarSwitch');
			},
			error: function () {
				console.log('error');
			}
		});
	}

	function startFilter(form) {
		const formData = serializeFormData(form);
		if (formData['prevFilterCategory'] !== formData['filterCategory']) {
			delete formData.filterItem;
			delete formData.prevFilterCategory;
		}
		const qs = getQueryString(form, formData);
		window.history.pushState({href: qs}, '', qs);
		formData['ts'] = Date.now();
		ajaxCall(form, formData);
	};

	function startPhonebookFilter(form) {
		const formData = serializeFormData(form);
		if (formData['prevFilterSection'] !== formData['filterSection']) {
			delete formData.filterDepartment;
			delete formData.prevFilterSection;
		}
		if (!formData['filterSection']) {
			delete formData.filterSection;
		}
		if (!formData['filterDepartment']) {
			delete formData.filterDepartment;
		}
		if (!formData['prevFilterSection']) {
			delete formData.prevFilterSection;
		}
		if (!formData['filterText']) {
			delete formData.filterText;
		}
		const qs = getQueryString(form, formData);
		window.history.pushState({href: qs}, '', qs);
		formData['ts'] = Date.now();
		ajaxCall(form, formData);
	};

	function serializeFormData(form) {
		const formData = form.serializeArray().reduce(function (obj, item) {
			obj[item.name] = item.value;
			return obj;
		}, {});

		return formData;
	};

	function getQueryString(form, formData) {
		return form.attr('action') + (Object.keys(formData).length ? '?' : '') + Object.keys(formData)
				.map(key => `${key}=${formData[key]}`)
				.join('&');
	}

	$('body').on('click', 'a.ajax', function (event) {
		event.preventDefault();
		start(this);
	});

	$('body').on('change', 'select.ajax', function (event) {
		event.preventDefault();
		const form = $(this).closest('form');
		form.submit();
	});

	$('body').on('submit', 'form.ajax', function (event) {
		event.preventDefault();
		const form = $(this);
		if (form.attr('data-type') === 'phonebook') {
			startPhonebookFilter(form);
		} else {
			startFilter(form);
		}
	});

	// let myTypingTimer = 0;                //timer identifier
	// const myDoneTypingInterval = 600;  //time in ms
	//
	// $('input[name=filterText]').keyup(function(){
	// 	const form = $(this).closest('form');
	// 	clearTimeout(myTypingTimer);
	// 	if ($('input[name=filterText]').val()) {
	// 		myTypingTimer = setTimeout(function () {
	// 			form.submit();
	// 		}, myDoneTypingInterval);
	// 	}
	// });

	// $('body').on('keyup', 'input[name=filterText]', function (event) {
	// 	let myTypingTimer = 0;                //timer identifier
	// 	const myDoneTypingInterval = 1000;  //time in ms
	// 	event.preventDefault();
	//
	// 	const form = $(this).closest('form');
	//
	// 	if ($(this).val()) {
	// 		clearTimeout(myTypingTimer);
	// 		myTypingTimer = setTimeout(function () {
	// 			form.submit();
	// 		}, myDoneTypingInterval);
	// 	}
	// });

	$(window).on('popstate', function (e) {
		var state = e.originalEvent.state;
		if (state !== null) {
			start(state, true);
		} else {
			window.location.reload(true);
		}
	});

	$("#new_reservation_form_submit").click(function (e) {
		var isValid = true;
		$('#newReservation-form input').each(function() {
			if(!this.checkValidity()) {
				isValid = false;
			}
		}) ;
		if (isValid) {
			setTimeout(function () { disableButton(); }, 0);
		}
	});

	function disableButton() {
		$("#new_reservation_form_submit").prop('disabled', true);
	}

	$("#contact_form_submit").click(function (e) {
		var isValid = true;
		$('#contact-form input').each(function() {
			if(!this.checkValidity()) {
				isValid = false;
			}
		}) ;
		if (isValid) {
			setTimeout(function () { disableCotnactFormButton(); }, 0);
		}
	});

	function disableCotnactFormButton() {
		$("#contact_form_submit").prop('disabled', true);
	}
});